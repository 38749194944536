import { DEFAULT_CONTROL_NAME } from '@/constants/formWorkflow';

export const mappedControls = (arr) => arr.map((el) => ({
  ...el,
  settings: el.settings.map((set) => {
    if (set.techName === 'values') {
      const id = Number(window.HM_CURRENT_UNIQID());
      return {
        ...set,
        values: [{
          techId: id,
          name: 'Элемент',
          sortOrder: 1,
          connectedGroups: [],
        }],
      };
    }
    if (set.techName === 'datesToShowType') {
      return {
        ...set,
        value: 0,
      };
    }
    if ([
      'minRangeDate', 'maxRangeDate', 'minDate', 'maxDate',
      'minRelativeDate', 'maxRelativeDate', 'excludeMinRelativeDate',
      'excludeMaxRelativeDate', 'excludeHolidays', 'replaceDirection', 'cmdbObject',
    ].includes(set.techName)) {
      return {
        ...set,
        value: '',
      };
    }
    if (set.techName === 'name') {
      return {
        ...set,
        value: set.name || DEFAULT_CONTROL_NAME,
      };
    }
    if (set.techName === 'scaleValues') {
      const id = Number(window.HM_CURRENT_UNIQID());
      return {
        ...set,
        values: [{
          id,
          techId: id,
          name: 'Имя',
          extendedValues: [
            {
              value: 'Значение',
              type: 'value',
            },
          ],
          sortOrder: 1,
          connectedGroups: [],
        }],
      };
    }
    return set;
  }),
}));

export const setOrder = (arr) => arr.map((el, index) => {
  if (el.classType === 'group') {
    // eslint-disable-next-line
    el.childs = setOrder(el.childs);
  }
  if (el.classType === 'table') {
    // eslint-disable-next-line
    el.columns = setOrder(el.childs);
    // eslint-disable-next-line
    delete el.childs;
  }
  return {
    ...el,
    sortOrder: index + 1,
  };
});

export const setTechId = (arr) => arr.map((el) => {
  if (el.settings) {
    // eslint-disable-next-line
    el.settings = el.settings.map((s) => {
      // eslint-disable-next-line
      if (s.techName === 'values') s.values = setTechId(s.values);
      return s;
    });
  }
  if (el.classType === 'group') {
    // eslint-disable-next-line
    el.childs = el.childs ? setTechId(el.childs) : [];
  }
  if (el.classType === 'table') {
    // eslint-disable-next-line
    el.childs = el.columns ? setTechId(el.columns) : [];
    // eslint-disable-next-line
    delete el.columns;
  }
  return {
    techId: el.id,
    ...el,
  };
});

export const filterElementsBySearch = (element, searchQuery) => {
  const query = searchQuery.trim().toLowerCase();

  // by id
  if (element.id && `${element.id}`.includes(query)) {
    return true;
  }

  // by name
  const techName = element.classType === 'text' ? 'simpleTextValue' : 'name';
  const label = element.settings.find((item) => item.techName === techName)?.value;
  const name = label?.replace(/(<([^>]+)>)/gi, '');
  if (name) {
    const hasSame = name.trim().toLowerCase().includes(query);
    if (hasSame) return true;
  }

  // by values
  const values = element.settings.find((item) => item.techName === 'values')?.values;
  if (values?.length) {
    const hasSame = values.some((value) => value.name.trim().toLowerCase().includes(query));
    if (hasSame) return true;
  }

  return false;
};

export const flatElements = (elements = []) => {
  const flattedElements = [];

  const setElement = (element) => {
    flattedElements.push({ ...element, childs: [] });
    if (element.childs?.length) {
      element.childs.forEach((child) => setElement(child));
    }
  };
  elements.forEach((element) => setElement(element));

  return flattedElements;
};
