import dayjs from 'dayjs';
import checkVisible from '@/components/hm-form/controls/hm-group/checkVisible';
import getGroupDeps from '@/components/hm-form/getGroupDeps';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';

const isEmptyValue = (v) => {
  if (Array.isArray(v)) return !v.length;
  if (typeof v === 'object' && !!v) {
    if (v instanceof Date) return !v;

    return !Object.keys(v).length;
  }
  if (typeof v === 'boolean' || typeof v === 'number') return false;
  return !v;
};

const graphCalendarVal = (model, hasTime) => {
  const format = hasTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
  // Если mode = single
  if (!Array.isArray(model)) {
    return dayjs(model).format(format);
  }
  return model.map((item) => dayjs(item).format(format)).join(' - ');
};

/**
 * Получение индексов файлов, вложенных в группе, из общего списка файлов формы.
 * @param model {object} - модель формы
 * @param fileIds {string[]} - массив id файлов формы
 * @returns {number[]} - массив индексов
 */
const getFileIndexesInModel = (model, fileIds) => {
  const indexes = [];
  const innerModel = cloneDeep(model);

  // нужно в любом случае удалять дочерние группы,
  // чтобы доставать индексы только для первой вложенности группы
  delete innerModel.addedGroups;

  Object.keys(innerModel).forEach((key) => {
    // если это группа, непосредственно в которой есть файлы
    if (isArray(innerModel[key]) && innerModel[key].every((el) => el instanceof File)) {
      fileIds.forEach((fileId, index) => {
        const isFileExists = innerModel[key].findIndex((file) => file.id === fileId);

        if (isFileExists !== -1) indexes.push(index);
      });
    }
  });

  return indexes;
};

/**
 * Маппинг данных для отправки на сервер при сохранении тикета
 * @param model {object} - значения контролов
 * @param fields {array} - контролы на форме
 * @param isTableRowItems {boolean} - находятся ли контролы в ячейках таблицы
 * @param checklistFileList {array} - массив файлов всего чеклиста
 * @return {*[]}
 */
const mappedFields = (model, fields, isTableRowItems = false, checklistFileList = []) => {
  const arr = [];
  const fileIds = checklistFileList.map((file) => file.id);

  fields.forEach((field) => {
    const hasModel = model && model[field.id];
    if (hasModel || isTableRowItems || field.class === 'table' || isNumber(hasModel)) {
      if (['uploader', 'text'].includes(field.class)) return;

      // Эти свойства есть всегда у всех типов контроллов
      let fieldModel = {
        id: field.id,
        classType: field.class,
        label: field.name,
      };

      // Если модель таблицы пуста или есть пустые поля, то нужно заполнить пустыми значениями
      // бек всегда ожидает хотя бы одну строку со всеми полями
      if (!hasModel && isTableRowItems) {
        fieldModel.value = null;
        arr.push(fieldModel);
        return;
      }

      if (((!hasModel && !isNumber(hasModel)) || isEmptyValue(model[field.id])) && field.class !== 'table') return;

      // Эти свойства могут быть у всех типов контроллов
      if (field.externalSystemName) {
        fieldModel.externalSystemName = field.externalSystemName;
        if (field.externalSystemFieldName) {
          fieldModel.externalSystemFieldName = field.externalSystemFieldName;
        }
      }
      if (field.smTechField) {
        fieldModel.smTechField = field.smTechField;
      }

      switch (field.class) {
      case 'input':
      case 'textarea':
      case 'calendar':
      case 'contactInfo':
      case 'counter':
      case 'calculatedField':
        fieldModel.value = model[field.id];
        break;
      case 'graphCalendar':
        fieldModel.value = graphCalendarVal(model[field.id], field.showTime);
        break;
      case 'checkboxGroup':
        fieldModel.itemsValues = field.listValues.map((el) => ({
          id: el.id,
          label: el.name,
          value: model[field.id].includes(el.id),
        })).filter((i) => i.value);
        fieldModel.value = fieldModel.itemsValues.map((el) => el.label).join(', ');
        break;
      case 'radioGroup':
        fieldModel.itemsValues = field.listValues.map((el) => ({
          id: el.id,
          label: el.name,
          value: model[field.id].includes(el.id),
        }));
        fieldModel.value = fieldModel.itemsValues.find((el) => el.value).label;
        break;
      case 'priority':
        fieldModel.valueId = model[field.id];
        fieldModel.value = field.listValues.find((el) => el.id === model[field.id]).name;
        break;
      case 'select':
        fieldModel.valueId = model[field.id];
        fieldModel.value = field.listValues.find((el) => el.id === model[field.id]).name;
        fieldModel.sendInHiddenGroup = field.sendInHiddenGroup;
        break;
      case 'search':
        fieldModel.searchResults = model[field.id];
        fieldModel.searchType = field.searchType || 'byEmployee';
        fieldModel.customEmployeeListId = field.customEmployeeListId;
        break;
      case 'network':
        fieldModel.value = JSON.stringify(model[field.id]);
        break;
      case 'fiasSearch':
        fieldModel.value = model[field.id];
        break;
      case 'employeeReplacement':
        fieldModel.chosenWorkPlace = model[field.id];
        break;
      case 'resourceField':
        if (
          model[field.id][0] && model[field.id][0].resource
          && model[field.id][0].resource.name
        ) {
          fieldModel.value = model[field.id][0].resource.name;
          fieldModel.resources = model[field.id];
        }
        break;
      case 'table':
        fieldModel.rows = model[field.id]
          ? model[field.id].map((el) => mappedFields(el.value, el.fields, true))
          : [mappedFields({}, field.columns, true)];
        break;
      case 'group':
        fieldModel.groupId = field.groupId;
        fieldModel.controlsDisplayType = field.controlsDisplayType === 'horizontal' ? 'horizontal' : 'vertical';
        fieldModel.value = field.name;
        // eslint-disable-next-line no-case-declarations
        const childs = mappedFields(model[field.id], field.childs, undefined, checklistFileList);
        // eslint-disable-next-line no-case-declarations
        const visible = checkVisible(field.visible, model, field.id, getGroupDeps(fields));
        if (childs?.length && !visible) {
          fieldModel.groupItems = childs.filter((child) => {
            if (child.classType === 'select' && child.sendInHiddenGroup) {
              return child;
            }
            if (child.classType === 'cmdb' && child.sendInHiddenGroup) {
              return child;
            }
            if (child.classType !== 'select' && child.classType !== 'cmdb') return child;
            return false;
          });
        } else {
          fieldModel.groupItems = childs;
        }

        fieldModel.attachmentsIndexes = getFileIndexesInModel(model[field.id], fileIds);

        // eslint-disable-next-line no-case-declarations
        const hasAddedGroups = model[field.id].addedGroups && model[field.id].addedGroups.length;
        // eslint-disable-next-line no-case-declarations
        const mapAddedGroups = (groups) => groups.map((el) => ({
          id: el.id,
          controlsDisplayType: field.controlsDisplayType === 'horizontal' ? 'horizontal' : 'vertical',
          value: field.name,
          label: field.name,
          classType: 'group',
          groupItems: mappedFields(el.value, field.childs, undefined, checklistFileList),
          attachmentsIndexes: getFileIndexesInModel(el.value, fileIds),
        }));

        fieldModel.addedGroups = hasAddedGroups ? mapAddedGroups(model[field.id].addedGroups) : [];
        if (!Object.keys(model[field.id]).length || !childs.length) fieldModel = null;
        break;
      case 'cmdb':
        if (!field.multipleValues) {
          const valueId = String(model[field.id]);
          const ke = field.cmdbItemInfoMap[valueId] || field.searchedItems.find((item) => item.id === valueId);

          if (!ke) {
            throw new Error(`Field with id ${field.id} has no required fields!`);
          }

          fieldModel.valueId = valueId;
          fieldModel.value = ke.text || ke.label;
          fieldModel.sendInHiddenGroup = field.sendInHiddenGroup;
        }
        break;
      case 'inlineInputs': // для SM тикетов
        arr.push(...mappedFields(model[field.id], field.childs));
        fieldModel = null;
        break;
      case 'database':
        fieldModel.databaseValues = model[field.id].map((el) => ({
          id: el.id,
          value: el.value,
          label: el.label,
          name: el.name,
          tableName: el.tableName,
          dataType: el.dataType,
          visualType: el.visualType,
        }));
        break;
      case 'scale':
        fieldModel.itemsValues = field.listValues.map((el) => ({
          id: el.id,
          label: el.name,
          extendedValue: el.value,
          value: el?.extendedValues[0]?.value === model[field.id],
        }));
        fieldModel.value = fieldModel.itemsValues.find((el) => el.value).label;
        break;
      case 'document':
        fieldModel.documents = model[field.id];
        break;
      default:
        break;
      }

      if (fieldModel) {
        if (fieldModel.classType === 'group' && (fieldModel.groupItems.length || fieldModel.addedGroups.length)) {
          arr.push(fieldModel);
        } else if (fieldModel.classType === 'cmdb' && field.multipleValues) {
          model[field.id].forEach((item) => {
            let valueId;
            const localFieldModal = { ...fieldModel };
            if (localFieldModal.isSearch) {
              valueId = String(item.id);
              localFieldModal.value = String(item.label);
            } else {
              valueId = String(item);
            }

            if (!field.isSearch && !field.cmdbItemInfoMap[valueId]) {
              throw new Error(`Field with id ${field} has no required fields!`);
            }
            if (!field.isSearch) localFieldModal.value = field.cmdbItemInfoMap[valueId].text;

            localFieldModal.valueId = valueId;
            arr.push(localFieldModal);
          });
        } else if (fieldModel.classType !== 'group') {
          arr.push(fieldModel);
        }
      }
    }
  });
  return arr;
};

export default mappedFields;
