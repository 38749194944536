<template>
  <div class="form-workflow">
    <validation-observer
      ref="form"
      tag="div"
      class="form-workflow"
    >
      <div class="form-workflow__block">
        <div class="form-workflow__row">
          <div class="form-workflow__col form-workflow__col--full-width">
            <esmp-select
              v-model="mode"
              placeholder="Выберите режим"
            >
              <esmp-select-option
                v-for="item in modesList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.text }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>
        <div class="form-workflow__row">
          <div class="form-workflow__col form-workflow__col--half-width">
            <esmp-select
              v-model="bandType"
              placeholder="Выберите тип по умолчанию"
            >
              <esmp-select-option
                v-for="item in bandTypeList"
                :key="item"
                :value="item"
              >
                {{ item }}
              </esmp-select-option>
            </esmp-select>
          </div>
          <div class="form-workflow__col form-workflow__col--half-width">
            <template v-if="bandType === bandTypes.workflow">
              <validation-provider
                rules="required"
                v-slot="v"
                name="Workflow по умолчанию"
              >
                <esmp-select
                  clearable
                  v-model="defaultWorkflowModel"
                  placeholder="Выберите workflow по умолчанию"
                  filterable
                >
                  <esmp-select-option
                    v-for="item in workflows"
                    :key="item.id"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </esmp-select-option>
                </esmp-select>
                <div v-if="v.errors.length" class="form-workflow__errors">
                  {{ v.errors[0] }}
                </div>
              </validation-provider>
            </template>
            <template v-else-if="bandType === bandTypes.queue">
              <validation-provider
                rules="required"
                v-slot="v"
                name="Очередь по умолчанию"
              >
                <esmp-select
                  clearable
                  v-model="defaultQueueModel"
                  placeholder="Выберите очередь по умолчанию"
                  filterable
                >
                  <esmp-select-option
                    v-for="item in queues"
                    :key="item.name"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </esmp-select-option>
                </esmp-select>
                <div v-if="v.errors.length" class="form-workflow__errors">
                  {{ v.errors[0] }}
                </div>
              </validation-provider>
              <template v-if="mode === modes.one.value">
                <esmp-checkbox
                  class="form-workflow__duty-queue"
                  v-model="hasDutyQueueModel"
                >
                  Нужна дежурная очередь?
                </esmp-checkbox>
                <validation-provider
                  v-if="hasDutyQueueModel"
                  rules="required"
                  v-slot="v"
                  name="Дежурная очередь"
                >
                  <esmp-select
                    class="form-workflow__duty-queue"
                    clearable
                    v-model="dutyQueueModel"
                    placeholder="Выберите дежурную очередь"
                    filterable
                  >
                    <esmp-select-option
                      v-for="item in dutyQueues"
                      :value="item.name"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </esmp-select-option>
                  </esmp-select>
                  <div v-if="v.errors.length" class="form-workflow__errors">
                    {{ v.errors[0] }}
                  </div>
                </validation-provider>
              </template>
            </template>
          </div>
        </div>
      </div>

      <esmp-collapser
        v-if="mode === modes.many.value"
        class="form-workflow__block form-workflow__block--collapser"
        :title="isManyRelationExpanded ? 'Скрыть' : 'Развернуть'"
        :is-expanded.sync="isManyRelationExpanded"
      >
        <div class="form-workflow__row">
          <div class="form-workflow__col form-workflow__col--full-width">
            <esmp-select
              v-model="control"
              placeholder="Выберите поле"
            >
              <esmp-select-option
                v-for="item in controls"
                :value="item.value"
                :key="item.value"
              >
                {{ item.text }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>

        <template v-if="activeControl">
          <div
            class="form-workflow__row"
            v-for="(item, index) in settingsList"
            :key="item.techId"
          >
            <div class="form-workflow__col form-workflow__col--many-dependency">
              <esmp-select
                v-model="item.fieldOption"
                placeholder="Выберите значение"
              >
                <esmp-select-option
                  v-for="dis in disabledItems(item)"
                  :key="dis.techId"
                  :value="dis.techId"
                >
                  {{ dis.name }}
                </esmp-select-option>
              </esmp-select>
            </div>

            <div class="form-workflow__col form-workflow__col--many-dependency">
              <esmp-select
                v-model="item.type"
                placeholder="Выберите тип"
              >
                <esmp-select-option
                  v-for="band in bandTypeList"
                  :key="band"
                  :value="band"
                >
                  {{ band }}
                </esmp-select-option>
              </esmp-select>
            </div>

            <div class="form-workflow__col form-workflow__col--many-dependency">
              <esmp-select
                v-if="item.type === bandTypes.workflow"
                v-model="item.workflow"
                placeholder="Выберите workflow"
                filterable
              >
                <esmp-select-option
                  v-for="workflow in workflows"
                  :key="workflow.id"
                  :value="workflow.name"
                >
                  {{ workflow.name }}
                </esmp-select-option>
              </esmp-select>
              <esmp-select
                v-else-if="item.type === bandTypes.queue"
                v-model="item.queue"
                placeholder="Выберите очередь"
                filterable
              >
                <esmp-select-option
                  v-for="queue in queues"
                  :key="queue.id"
                  :value="queue.name"
                >
                  {{ queue.name }}
                </esmp-select-option>
              </esmp-select>
            </div>

            <div class="form-workflow__col form-workflow__col--many-dependency">
              <esmp-button
                v-if="settingsList.length > 1"
                icon="trash"
                icon-position="right"
                @click="removeSettingsItem(index)"
              />
            </div>
          </div>
          <div v-if="settingsList < activeControlValues.values" class="form-workflow__row">
            <div class="form-workflow__col">
              <esmp-button
                view="outline"
                @click="addSettingsItem"
              >
                Добавить workflow/очередь
              </esmp-button>
            </div>
          </div>
        </template>

        <div class="form-workflow__row form-workflow__row--right form-workflow__row--big-offset">
          <div class="form-workflow__col">
            <esmp-button @click="save">
              Сохранить настройки множественного режима
            </esmp-button>
          </div>
        </div>
      </esmp-collapser>

      <esmp-collapser
        v-if="mode === modes.booleanMany.value"
        class="form-workflow__block form-workflow__block--collapser"
        :title="isMultiRelationExpanded ? 'Скрыть' : 'Развернуть'"
        :is-expanded.sync="isMultiRelationExpanded"
      >
        <form-dependencies
          :fields="fields"
          :wf-mode="wfMode"
          :workflows="workflows"
          :queues="queues"
          :dependencies.sync="deps"
        />
      </esmp-collapser>
    </validation-observer>
  </div>
</template>

<script>
import FormDependencies from '@/components/form-dependencies';
import { MODES, BAND_TYPES } from '@/constants/formWorkflow';

export default {
  name: 'FormWorkflow',
  components: {
    FormDependencies,
  },
  props: {
    fields: {
      type: Array,
      default: undefined,
    },
    wfMode: {
      type: String,
      default: undefined,
    },
    flowType: {
      type: String,
      default: undefined,
    },
    defaultWorkflow: {
      type: String,
      default: undefined,
    },
    defaultQueue: {
      type: String,
      default: undefined,
    },
    dutyQueue: {
      type: String,
      default: undefined,
    },
    hasDutyQueue: {
      type: Boolean,
    },
    dependencies: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      modes: Object.freeze(MODES),
      bandType: null,
      workflows: [],
      queues: [],
      control: null,
      activeControl: null,
      settingsList: [],
      bandTypes: Object.freeze(BAND_TYPES),
      isManyRelationExpanded: true,
      isMultiRelationExpanded: true,
    };
  },
  computed: {
    mode: {
      get() {
        return this.wfMode;
      },
      set(newVal) {
        if (this.mode !== newVal && this.mode === MODES.booleanMany.value) {
          this.$emit('update:dependencies', []);
        }
        this.$emit('update:wfMode', newVal);
      },
    },
    deps: {
      get() {
        return this.dependencies;
      },
      set(value) {
        this.$emit('update:dependencies', value);
      },
    },
    defaultWorkflowModel: {
      get() {
        return this.defaultWorkflow;
      },
      set(newVal) {
        this.$emit('update:defaultWorkflow', newVal);
      },
    },
    defaultQueueModel: {
      get() {
        return this.defaultQueue;
      },
      set(newVal) {
        this.$emit('update:defaultQueue', newVal);
      },
    },
    dutyQueueModel: {
      get() {
        return this.dutyQueue;
      },
      set(newVal) {
        this.$emit('update:dutyQueue', newVal);
      },
    },
    hasDutyQueueModel: {
      get() {
        return this.hasDutyQueue;
      },
      set(newVal) {
        this.$emit('update:hasDutyQueue', newVal);
      },
    },
    controls() {
      const controls = ['select', 'checkboxGroup', 'radioGroup'];
      const contorls = this.fields
        .filter((item) => controls.indexOf(item.classType) > -1)
        .map((item) => {
          const nameField = item.settings.find((s) => s.techName === 'name');
          return {
            value: item.techId,
            text: nameField.name,
          };
        });
      const activeControls = this.fields.filter((c) => {
        if (!c.settings) return false;
        const setting = c.settings.find((s) => s.techName === 'values');
        if (!setting) {
          return contorls;
        }
        const { values } = setting;
        let r = false;
        values.forEach((v) => {
          if (v.workflowName) {
            r = true;
          }
        });
        return r;
      });
      if (activeControls.length) {
        const [c] = activeControls;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.control = c.techId;
      }
      return contorls;
    },
    dutyQueues() {
      if (this.defaultQueueModel) {
        return this.queues.filter((q) => q.name !== this.defaultQueueModel);
      }

      return this.queues;
    },

    activeControlValues() {
      return this.activeControl.settings.find((s) => s.techName === 'values');
    },
    modesList() {
      return Object.values(this.modes);
    },
    bandTypeList() {
      return Object.values(this.bandTypes);
    },
    currentBandKey() {
      return Object.keys(this.bandTypes).find(
        (key) => this.bandTypes[key] === this.bandType,
      );
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    getNewSettingsItem() {
      const item = {
        techId: +new Date(),
        fieldOption: null,
        type: `${this.bandType}`,
        workflow: this.defaultWorkflowModel
          ? `${this.defaultWorkflowModel}`
          : null,
        queue: this.defaultQueueModel ? `${this.defaultQueueModel}` : null,
      };
      return item;
    },
    addSettingsItem() {
      this.settingsList.push(this.getNewSettingsItem());
    },
    removeSettingsItem(index) {
      if (this.settingsList && this.settingsList.length >= index) {
        this.settingsList.splice(index, 1);
      }
    },
    reset() {
      this.$emit('reset', this.activeControl.techId);
    },
    save() {
      if (this.mode === this.modes.one.value) return;
      if (!this.activeControl) {
        this.$EsmpNotify.$show('Ошибка сохранения workflow, выберите поле или смените режим', 'error');
        return;
      }
      if (
        this.settingsList.length === 1
        && !(this.settingsList[0].workflow || this.settingsList[0].queue)
      ) {
        this.$EsmpNotify.$show('Ошибка сохранения workflow, выберите workflow/очередь или смените режим', 'error');
        return;
      }
      this.$emit('save', {
        elementId: this.activeControl.techId,
        options: this.settingsList,
      });
      this.reset();
    },
    disabledItems(activeValue) {
      const t = this.activeControl.settings.find((s) => s.techName === 'values');
      if (!t) {
        return [];
      }
      const { values } = t;
      return values.map((item) => {
        const value = { ...item };

        let disabled;

        if (activeValue && item.value === activeValue.fieldOption) disabled = false;
        else {
          disabled = this.settingsList.some(
            (setting) => setting.fieldOption === item.value,
          );
        }

        value.disabled = !!disabled;

        return value;
      });
    },
    setActiveControl() {
      this.settingsList = [];

      if (this.control) {
        const c = this.fields.find((field) => field.techId === this.control);
        const t = c.settings.find((s) => s.techName === 'values');
        if (!t) {
          return null;
        }
        const { values } = t;
        const newList = [];
        values.forEach((v, ind) => {
          if (v.workflowName || v.queueName) {
            const item = {
              fieldOption: v.techId,
              techId: `${+new Date()}_${ind}`,
              workflow: v.workflowName || null,
              queue: v.queueName || null,
              type: v.workflowName
                ? `${this.bandTypes.workflow}`
                : `${this.bandTypes.queue}`,
            };

            newList.push(item);
          }
        });
        if (newList.length) {
          this.settingsList = newList;
        } else {
          this.settingsList = [this.getNewSettingsItem()];
        }
        this.activeControl = c;
      } else {
        this.activeControl = null;
      }
      return undefined;
    },
    getActiveControl() {
      if (this.fields && this.fields.length) {
        const activeControl = this.fields.find((c) => {
          const values = c.settings.find((s) => s.techName === 'values');

          if (values && values.values) {
            return values.values.some((v) => v.queueName || v.workflowName);
          }

          return false;
        });

        if (activeControl) {
          this.control = activeControl.techId;
        }
      }
    },
  },
  watch: {
    flowType: {
      handler(v) {
        this.bandType = v
          ? this.bandTypes[v]
          : this.bandTypes.workflow;
        if (this.defaultQueue && !this.bandType) this.bandType = `${this.bandTypes.queue}`;
      },
      deep: true,
      immediate: true,
    },
    control() {
      this.setActiveControl();
    },
    bandType(val) {
      if (val === this.bandTypes.workflow) {
        this.defaultQueueModel = null;
        this.hasDutyQueueModel = false;
      }

      if (val === this.bandTypes.queue) {
        this.defaultWorkflowModel = null;
      }

      if (val === this.bandTypes.queueGroup) {
        this.defaultWorkflowModel = null;
        this.defaultQueueModel = null;
      }
    },
    currentBandKey: {
      immediate: true,
      handler() {
        this.$emit('update:flowType', this.currentBandKey);
      },
    },
    hasDutyQueueModel(hasDutyQueueModel) {
      if (!hasDutyQueueModel) {
        this.dutyQueueModel = null;
      }
    },
    mode(mode) {
      if (mode !== this.modes.one.value) {
        this.hasDutyQueueModel = false;
      }
    },
  },
  async created() {
    const { serviceId } = this.$route.params;

    Promise.all([
      this.$API.workflow.getWorkflows(serviceId),
      this.$API.workflow.getQueues(serviceId),
    ]).then(([res1, res2]) => {
      this.workflows = res1.data.length ? res1.data.sort((a, b) => a.name.localeCompare(b.name)) : [];
      this.queues = res2.data.length ? res2.data.sort((a, b) => a.name.localeCompare(b.name)) : [];

      this.getActiveControl();
    });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/blocks/form-workflow.scss';
</style>
