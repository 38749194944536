import uniqueId from 'lodash/uniqueId';

/**
 * Получение массива полей для формы, прошедшего валидацию по каждому полю.
 * Нужно для того, чтобы избежать ошибок модели из-за разных моделей полей от бека.
 * @param checklist {array} - массив
 * @return {array} - массив исправленных полей
 */
export default (checklist) => (checklist
  ? checklist.map((field) => {
    const fixedField = field;

    // eslint-disable-next-line default-case
    switch (fixedField.class) {
    case 'select':
    case 'checkboxGroup':
      fixedField.listValues = fixedField.listValues.map((listValue) => ({
        ...listValue,

        // Иногда id у значений селекта может отсутствовать (пример tickets/create/otrs1/5)
        // TODO: убрать после перехода с SM форм
        id: listValue.id ?? uniqueId(`${fixedField.class}-id-`),
      }));
      break;
    }

    return fixedField;
  })
  : []);
