<template>
  <div class="page page--offset">
    <esmp-tabs :animated="false" v-model="activeTab">
      <template v-if="activeTab === 'construct'" #extra>
        <div
          v-if="!isImportMode"
          class="edit-form__right-bar"
        >
          <div class="edit-form__search">
            <esmp-input
              class="edit-form__search-field"
              label="Поиск по контролам"
              size="small"
              icon="search"
              clearable
              v-model="elementSearchQuery"
              @enter-exact="searchElement"
              @button-click="searchElement"
              @focusout="searchFocusOut"
              @cleared="searchFocusOut"
            />
          </div>
          <div @click="toggleView" class="edit-form__expander">
            <esmp-icon :name="expandAll ? 'arrow-up' : 'arrow-down'" />
            <span class="edit-form__expander-text">
              {{ expandAll ? 'Скрыть' : 'Раскрыть' }} все контролы
            </span>
          </div>
        </div>
      </template>
      <esmp-tab-pane
        label="Конструктор"
        name="construct"
      >
        <service-form
          v-if="!isImportMode"
          v-model="filteredElementList"
          :controls="controlList"
          :dependencies="dto.formDependencies"
          :is-searching="isSearching"
        />

        <esmp-upload
          v-else
          v-model="fileForImport"
          class="esmp-upload--as-drag"
          :multiple="false"
          :max-size="MAX_SIZE_UPLOAD_JSON"
          :format="['json']"
        >
          <div class="esmp-upload__zone--drag">
            Выберите файл в формате JSON для импорта формы
          </div>
        </esmp-upload>
      </esmp-tab-pane>
      <esmp-tab-pane
        label="Настройки формы"
        name="setting"
      >
        <div class="setting-form-table-wrapper">
          <table class="setting-form-table">
            <tr v-if="serviceInfo.name">
              <td>
                Сервис
              </td>
              <td>
                <span>{{ serviceInfo.name }}</span>
              </td>
            </tr>
            <tr v-if="serviceInfo.type">
              <td>Тип сервиса</td>
              <td>
                <span>{{ serviceInfo.type }}</span>
              </td>
            </tr>
            <tr>
              <td>Название шаблона</td>
              <td>
                <esmp-input v-model="dto.name" />
              </td>
            </tr>
            <tr>
              <td>
                Техническое имя формы<br>
                <span class="setting-form-table__notice-title">Внимание!</span><br>
                <span class="setting-form-table__notice-text">
                  Будьте внимательны при изменении этого поля и не меняйте значение
                  в случае неуверенности в последствиях
                </span>
              </td>
              <td>
                <esmp-input v-model="dto.techName" />
              </td>
            </tr>
            <tr>
              <td>Групповые заявки</td>
              <td>
                <esmp-switch
                  v-model="dto.isGroupTicketShown"
                  shape="circular"
                />
              </td>
            </tr>
            <tr>
              <td>Отображать сервис в теме</td>
              <td>
                <esmp-switch
                  v-model="dto.isServiceNameAddedToSubject"
                  shape="circular"
                />
              </td>
            </tr>
            <tr>
              <td>Кнопка - Создать заявку</td>
              <td>
                <esmp-switch
                  v-model="dto.isCreateTicketButtonShown"
                  shape="circular"
                />
              </td>
            </tr>
            <tr v-if="ticketStates.length">
              <td>Статус заявки по умолчанию</td>
              <td>
                <esmp-select v-model="ticketState">
                  <esmp-select-option
                    v-for="item in ticketStates"
                    :value="item.name"
                    :key="item.name"
                  >
                    {{ item.translatedName }}
                  </esmp-select-option>
                </esmp-select>
              </td>
            </tr>
            <tr>
              <td>Категория заявки</td>
              <td>
                <span>{{ serviceInfo.baseTicketType }}</span>
              </td>
            </tr>
            <tr v-if="impactLevelList.length">
              <td>Влияние</td>
              <td>
                <esmp-select v-model="dto.itsmImpactLevel">
                  <esmp-select-option
                    v-for="item in impactLevelList"
                    :value="item.name"
                    :key="item.id"
                  >
                    {{ item.translatedName }}
                  </esmp-select-option>
                </esmp-select>
              </td>
            </tr>
            <tr>
              <td>Комментарий</td>
              <td>
                <esmp-input
                  v-model="dto.comment"
                  :options="{ type: 'textarea' }"
                  class="setting-form-table__comment"
                />
              </td>
            </tr>
          </table>
        </div>
      </esmp-tab-pane>
      <esmp-tab-pane
        label="Настройки workflow/очередей"
        name="workflow"
        v-if="$route.params.serviceId"
      >
        <form-workflow
          ref="formWorkflow"
          :fields="elementList"
          :default-workflow.sync="defaultWorkflow"
          :default-queue.sync="defaultQueue"
          :duty-queue.sync="dto.dutyQueue"
          :wf-mode.sync="dto.wfMode"
          :flow-type.sync="dto.flowType"
          :has-duty-queue.sync="hasDutyQueue"
          :dependencies.sync="dto.formDependencies"
          @save="saveWf"
          @reset="resetWf"
        />
      </esmp-tab-pane>
    </esmp-tabs>
    <div
      :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
    >
      <esmp-button
        v-if="isImportMode"
        class="page__action-button"
        :disabled="!fileForImport.length"
        @click="importForm"
      >
        Импортировать
      </esmp-button>
      <esmp-button
        v-else
        class="page__action-button"
        @click="save(false)"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        class="page__action-button"
        v-if="$route.params.serviceId && !isImportMode"
        @click="save(true)"
        view="outline"
      >
        Сохранить и выйти
      </esmp-button>
      <esmp-button
        class="page__action-button page__action-button--right"
        v-if="$route.params.serviceId && !isImportMode"
        @click="openClientForm"
        view="outline"
      >
        Предпросмотр формы
      </esmp-button>
    </div>
    <esmp-modal
      v-model="isShowedClientFormModal"
      width="1100"
      class-name="vertical-center-modal"
      footer-hide
      scrollable
      @on-hidden="clientChecklist = null"
    >
      <template #header>
        Просмотр формы
      </template>
      <template>
        <div class="client-form">
          <esmp-loader v-if="!clientChecklist" />
          <hm-form
            v-else
            :fields="clientChecklist"
            v-model="clientModel"
          />
        </div>
      </template>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { BAND_TYPES, MODES } from '@/constants/formWorkflow';
import ServiceForm from '@/components/service-form';
import FormWorkflow from '@/components/form-workflow';
import Hub from '@/plugins/event-hub';
import HmForm from '@/components/hm-form/';
import mappedFields from '@/components/hm-form/helpers/mappedFields';
import getValidChecklistFields from '@/components/hm-form/helpers/getValidChecklistFields';
import createDefaultModel from '@/components/hm-form/helpers/createDefaultModel';
import { MAX_SIZE_UPLOAD_JSON } from '@/constants/uploadFiles';
import getJsonTypeBlob from '@/helpers/getJsonTypeBlob';
import { getFormEditPath } from '@/helpers/pathGenerator';
import {
  mappedControls,
  setOrder,
  setTechId,
  filterElementsBySearch,
  flatElements,
} from '@/helpers/controls';

export default {
  name: 'EditForm',
  components: {
    FormWorkflow,
    ServiceForm,
    HmForm,
  },
  data() {
    return {
      activeTab: 'construct',
      fileForImport: [],
      controlList: [],
      elementList: [],
      ticketStates: [],
      ticketTypesList: [],
      impactLevelList: [],
      serviceInfo: {},
      dto: {
        name: '',
        techName: '',
        comment: '',
        isDefaultForm: false,
        isGroupTicketShown: true,
        isCreateTicketButtonShown: true,
        isServiceNameAddedToSubject: false,
        ticketState: null,
        ticketTypes: [], // TODO артефакт, нужно убрать, когда выпилят на беке
        itsmImpactLevel: '',
        ticketDefaultType: '', // TODO артефакт, нужно убрать, когда выпилят на беке
        formItems: [],
        formDependencies: [],
        formSubscriptions: [],
        flowName: undefined,
        flowType: undefined,
        id: undefined,
        serviceId: undefined,
        queueGroupLogicNeedApplied: false,
        sourceSystem: 'otrs1',
        wfMode: 'single',
        serviceName: undefined,
        serviceType: undefined,
        dutyQueue: undefined,
      },
      expandAll: false,
      defaultWorkflow: '',
      defaultQueue: undefined,
      hasDutyQueue: false,
      filterElementList: [],
      isGlobalDefaultFormInService: false,
      isShowedClientFormModal: false,
      clientChecklist: null,
      clientModel: null,
    };
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapState('controlSearch', ['query', 'isSearching']),
    ...mapState('subscriptions', ['subscriptions']),
    elementSearchQuery: {
      get() {
        return this.query;
      },
      set(value) {
        this.setQuery(value);
      },
    },
    ticketState: {
      get() {
        return this.ticketStates.find((s) => s.name === this.dto.ticketState?.name)?.name || null;
      },
      set(name) {
        this.dto.ticketState = this.ticketStates.find((s) => s.name === name) || null;
      },
    },
    flattedElements() {
      return flatElements(this.elementList);
    },
    filteredElementList: {
      get() {
        if (this.isSearching) {
          return this.filterElementList;
        }
        return this.elementList;
      },
      set(updatedElementList) {
        this.elementList = updatedElementList;
      },
    },
    isCreateMode() {
      return this.$route.name === 'CreateForm';
    },
    isImportMode() {
      return this.$route.name === 'ImportForm';
    },
    isDefaultMode() {
      return !this.$route.params.serviceId;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('controlSearch', ['setQuery', 'setIsSearching']),
    ...mapActions('subscriptions', ['clearSubscriptions']),
    ...mapActions('ticket', { setClientForm: 'setForm' }),
    toggleView() {
      this.expandAll = !this.expandAll;
      Hub.$emit('toggle-views', this.expandAll);
    },
    validateFormDependencies() {
      let valid = true;
      let errorMessage = '';
      if (!this.dto.formDependencies || !this.dto.formDependencies.length) {
        errorMessage = `В режиме '${MODES.booleanMany.text}' необходимо добавить не менее одной зависимости`;
        valid = false;
      } else {
        this.dto.formDependencies.forEach((dependency, dependencyIndex) => {
          const queueGroupKey = Object.keys(BAND_TYPES).find(
            (key) => BAND_TYPES[key] === BAND_TYPES.queueGroup,
          );
          if (dependency.flowType !== queueGroupKey && !dependency.flowName) {
            errorMessage = `В зависимости №${dependencyIndex + 1} необходимо выбрать очередь/workflow`;
            valid = false;
          }
          if (!dependency.conditions || !dependency.conditions.length) {
            errorMessage = `В зависимости №${dependencyIndex + 1} необходимо добавить не менее одного условия`;
            valid = false;
          }
          dependency.conditions.forEach((condition, conditionIndex) => {
            if (!condition.controlId) {
              // eslint-disable-next-line max-len
              errorMessage = `В зависимости №${dependencyIndex + 1} необходимо выбрать контрол для условия №${conditionIndex + 1}`;
              valid = false;
            }
            if (!condition.listValueTechId) {
              // eslint-disable-next-line max-len
              errorMessage = `В зависимости №${dependencyIndex + 1} необходимо выбрать значение контрола для условия №${conditionIndex + 1}`;
              valid = false;
            }
          });
        });
      }
      if (!valid) this.$EsmpNotify.$show(errorMessage, 'error');
      return valid;
    },
    async prepareFormForSaving() {
      this.dto = omit(this.dto, 'formVersionId');
      this.dto.formItems = setOrder(cloneDeep(this.elementList));
      this.dto.flowName = this.defaultWorkflow || this.defaultQueue || '';

      if (!this.isDefaultMode && this.dto.flowType !== 'queueGroup' && !this.dto.flowName) {
        this.$EsmpNotify.$show('Ошибка сохранения workflow, выберите workflow/очередь или смените режим', 'error');

        throw new Error('Ошибка сохранения workflow, выберите workflow/очередь или смените режим');
      } if (this.dto.wfMode === 'booleanMultiple') {
        if (!this.validateFormDependencies()) {
          this.setLoading({ key: 'page', value: false });

          throw new Error('Ошибка валидации формы');
        }
        this.dto.formDependencies = this.dto.formDependencies.map((dep) => ({
          id: dep.id,
          flowType: dep.flowType,
          flowName: dep.flowName,
          conditions: dep.conditions.map((cond) => ({
            id: cond.id,
            formItemId: cond.controlId,
            listValueId: cond.listValueId
              ? cond.listValueId
              : cond.listValueTechId,
            operationType: cond.type,
          })),
        }));
      }
    },
    async importForm() {
      if (!this.fileForImport.length) {
        this.$EsmpNotify.$show('Загрузите файл для импорта!', 'error');
      }

      this.setLoading({ key: 'page', value: true });

      try {
        await this.prepareFormForSaving();

        const data = new FormData();

        data.append('metaFormService', getJsonTypeBlob(this.dto));
        data.append('fileControlsForm', this.fileForImport[0], this.fileForImport[0].name);

        const { data: formData } = await this.$API.admin.importForm(data);

        this.$EsmpNotify.$show('Форма импортирована успешно', 'success');

        await this.$router.push({ path: getFormEditPath(formData.serviceId, formData.id) });

        this.activeTab = 'construct';
      } catch (e) {
        this.$EsmpNotify.$show(e, 'error');
      } finally {
        this.setLoading({ key: 'page', value: false });
      }
    },
    async save(hasRedirect) {
      await this.prepareFormForSaving();

      let saveMethod;
      if (!this.$route.params.serviceId) {
        this.dto.serviceId = null;
        saveMethod = 'updateDefaultForm';
      } else if (this.isCreateMode || this.isGlobalDefaultFormInService) {
        /**
         * Если форм по сервису еще нет ни одной, то подгружается глобальная форма
         * и ее нужно скопировать, создав тем самым форму по умолчанию для этого сервиса
         * Если идет попытка скопировать форму по умолчанию по данному сервису, то необходимо сбросить
         * флаг isDefaultForm в false
         */
        this.dto = omit(this.dto, 'id');
        this.dto.isDefaultForm = this.isGlobalDefaultFormInService;
        saveMethod = 'createForm';
      } else {
        saveMethod = 'updateForm';
      }

      this.setLoading({ key: 'page', value: true });
      this.$API.forms[saveMethod](this.dto)
        .then(({ data }) => {
          this.$EsmpNotify.$show('Форма успешно сохранена', 'success');
          if (hasRedirect) {
            this.$router.push({ name: 'Forms', params: { serviceId: this.$route.params.serviceId } });
          } else {
            if (this.isCreateMode) {
              this.$router.push({ name: 'EditForm', params: { serviceId: data.serviceId, id: data.id } });
            }
            const newDto = { ...data };
            newDto.formDependencies = newDto.formDependencies.map((dep) => ({
              id: dep.id,
              flowType: dep.flowType,
              flowName: dep.flowName,
              conditions: dep.conditions.map((cond) => ({
                id: cond.id,
                listValueId: cond.listValueId,
                listValueTechId: cond.listValueId,
                type: cond.operationType,
                controlId: cond.formItemId,
              })),
            }));
            this.setForm(newDto);
            Hub.$emit('on-save-form');
          }
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    getControls() {
      return this.$API.controls.getControls().then(({ data }) => {
        this.controlList = mappedControls(data) || [];
      });
    },
    getForm(serviceId, id) {
      /**
       * Отсутствие serviceId указывает на то,
       * что эта форма по умолчанию для всех сервисов (route path - /default-form)
       * */
      if (!serviceId) {
        return this.$API.forms.getDefaultForm().then(({ data }) => this.setForm(data));
      }
      return this.$API.forms.getForm(serviceId, id).then(({ data }) => this.setForm(data));
    },
    setForm(data) {
      this.dto = data;
      this.hasDutyQueue = !!data.dutyQueue;
      this.elementList = setTechId(data.formItems);
      if (this.$route.params.serviceId) {
        this.defaultWorkflow = data.flowName;
        this.defaultQueue = data.flowName;
        if (data.serviceId === 'nullable') {
          this.isGlobalDefaultFormInService = true;
          this.dto.serviceId = this.$route.params.serviceId;
        } else {
          this.isGlobalDefaultFormInService = false;
        }
      }
    },
    getTicketStates() {
      return this.$API.admin.getStatuses(true).then(({ data: statuses }) => {
        if (!statuses.length) return;

        this.ticketStates = statuses;

        if (this.isCreateMode) [this.dto.ticketState] = statuses;
      });
    },
    getTicketTypes() {
      return this.$API.admin.getTicketTypes().then(({ data }) => {
        this.ticketTypesList = data;
        // TODO артефакт, нужно убрать, когда выпилят на беке
        if (this.isCreateMode) this.dto.ticketDefaultType = data[0].value;
      });
    },
    getServiceInfo(id) {
      return this.$API.services.getServiceInfo(id).then(({ data }) => {
        this.serviceInfo = data;
      });
    },
    getImpactLevels() {
      return this.$API.admin.getImpactLevels().then(({ data }) => {
        this.impactLevelList = data;
        if (this.isCreateMode) {
          const level = this.impactLevelList.find((l) => l.isDefault);
          this.dto.itsmImpactLevel = level?.name || '';
        }
      });
    },
    saveWf({ elementId, options }) {
      // TODO надо искать по группам и таблицам, а также по вложенным группам
      const elementIdx = this.elementList.findIndex(
        (el) => el.techId === elementId,
      );
      if (elementIdx < 0) {
        return;
      }
      const valuesListIdx = this.elementList[elementIdx].settings.findIndex(
        (s) => s.techName === 'values',
      );
      if (valuesListIdx < 0) {
        return;
      }
      const elCopy = cloneDeep(this.elementList);
      elCopy.forEach((el) => {
        const t = el.settings.find((s) => s.techName === 'values');
        if (!t) {
          return;
        }
        const { values } = t;
        if (values) {
          values.forEach((v) => {
            // eslint-disable-next-line no-param-reassign
            delete v.workflowName;
            // eslint-disable-next-line no-param-reassign
            delete v.queueName;
          });
        }
      });
      options.forEach((option) => {
        const control = elCopy[elementIdx].settings[valuesListIdx].values.find(
          (c) => c.techId === option.fieldOption,
        );
        if (control) {
          if (option.type === BAND_TYPES.workflow && option.workflow) control.workflowName = option.workflow;
          else if (option.type === BAND_TYPES.queue && option.queue) control.queueName = option.queue;
        }
      });
      this.elementList = elCopy;
    },
    resetWf(elementId) {
      const controls = ['select', 'checkboxGroup', 'radioGroup'];
      // TODO надо искать по группам и таблицам, а также по вложенным группам
      this.elementList
        .filter(
          (el) => el.id !== elementId && controls.indexOf(el.classType) > -1,
        )
        .forEach((el) => {
          const { values } = el.settings.find((s) => s.techName === 'values');
          if (values) {
            // eslint-disable-next-line no-param-reassign
            values.forEach((v) => delete v.workflowName);
          }
        });
    },
    async searchElement() {
      this.setLoading({ key: 'page', value: true });
      await this.$nextTick();
      await this.setIsSearching(!!this.elementSearchQuery.length);
      // eslint-disable-next-line max-len
      this.filterElementList = this.flattedElements.filter((element) => filterElementsBySearch(element, this.elementSearchQuery));
      await this.$nextTick();
      this.setLoading({ key: 'page', value: false });
    },
    async searchFocusOut() {
      if (!this.query && this.isSearching) {
        this.setLoading({ key: 'page', value: true });
        await this.$nextTick();
        this.filterElementList = [];
        this.setIsSearching(false);
        await this.$nextTick();
        this.setLoading({ key: 'page', value: false });
      }
    },
    startupForm() {
      this.dto.id = undefined;
      this.dto.name = this.$route.query.name;
      this.dto.serviceId = this.$route.params.serviceId;
      this.dto.formSubscriptions = this.subscriptions;
      this.clearSubscriptions();
    },
    async init() {
      if (this.isCreateMode) {
        if (this.$route.query.dublicatedFormId) {
          await this.getForm(this.$route.params.serviceId, this.$route.query.dublicatedFormId);
        }
        this.startupForm();
      } else if (this.isImportMode) {
        this.startupForm();
      } else {
        await this.getForm(this.$route.params.serviceId, this.$route.params.id);
      }
    },
    openClientForm() {
      this.isShowedClientFormModal = true;
      this.$API.checklist.getCheckListByFormId(this.dto.serviceId, {
        formId: this.dto.id,
        source: this.dto.sourceSystem,
      }).then(({ data }) => {
        this.clientChecklist = Object.freeze(getValidChecklistFields(data.fields));
        this.clientModel = createDefaultModel(this.clientChecklist);
      });
    },
    getClientForm() {
      /**
       * Подготовка данных формы для вычисляемого поля
       * и их запись в стор
       * */
      const form = {
        checklistItems: mappedFields(this.clientModel, this.clientChecklist, undefined),
        formId: this.dto.id,
        formVersionId: this.dto.formVersionId,
        formType: this.dto.formType,
      };
      this.setClientForm(form);
    },
  },
  created() {
    this.MAX_SIZE_UPLOAD_JSON = MAX_SIZE_UPLOAD_JSON;
    Hub.$on('get-form-data-for-calc', this.getClientForm);
    this.setLoading({ key: 'page', value: true });
    Promise
      .all([
        this.getControls(),
        this.getTicketTypes(),
        this.getTicketStates(),
        this.getImpactLevels(),
      ])
      .finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
  },
  beforeDestroy() {
    Hub.$off('get-form-data-for-calc', this.getClientForm);
    this.setClientForm(null);
  },
  watch: {
    '$route.path': {
      handler(newPath, oldPath) {
        if (newPath !== oldPath) {
          this.setLoading({ key: 'page', value: true });
          /**
           * Если мы находимся на странице - форма по умолчанию для всех сервисов (route path - /default-form),
           * то этот параметр будет отсутствовать и получать инфу по сервису не нужно
           * */
          if (this.$route.params.serviceId) this.getServiceInfo(this.$route.params.serviceId);
          else this.serviceInfo = {};
          this.init()
            .finally(() => {
              this.setLoading({ key: 'page', value: false });
            });
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.page__edit-form {
  padding-bottom: 80px;
}
table tr td:first-child {
  width: 350px;
}
table tr td {
  padding: 10px 10px 10px 0;
  &:first-child {
    text-align: right;
    color: $color-grayscale-60;
  }
}
.esmp-input ::v-deep textarea.esmp-input-element {
  height: 100px;
}

.edit-form {
  &__right-bar {
    display: flex;
    align-items: center;
  }
  &__expander {
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 200px;
    &:hover {
      cursor: pointer;
    }

    &-text {
      display: inline-block;
      margin-bottom: -4px;
    }
  }
  &__search-field  ::v-deep .esmp-input-element {
    background-color: $color-white;
  }
}

.setting-form-table-wrapper {
  padding: 20px;
  background: #fff;
  border-radius: 20px;
}

.setting-form-table {
  &__notice {
    &-title {
      color: $color-red;
    }
    &-text {
      font-size: 12px;
    }
  }
  &__comment {
    width: 400px;
  }
}
</style>
